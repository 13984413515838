<template>
  <div class="p-grid p-fluid">
    <div class="p-col-12">
      <div class="card">
        <DataTable
            dataKey="id"
            responsiveLayout="scroll"
            :rows="15"
            :paginator="true"
            :lazy="true"
            :value="restaurants.data"
            :totalRecords="restaurants.total"
            :rowHover="true"
            :loading="loading"
            @page="query.page = $event.page + 1"
            filterDisplay="menu">
          <template #header>
            <div class="p-grid">
              <h2 style="display: inline-block; width: 30%">Restaurantes</h2>
              <div style="width: 60%"></div>
              <Button label="Crear restaurante" class="p-button-sm" style="display: inline-block; width: auto" @click="create()"/>
            </div>

            <div class="p-d-flex p-jc-between">
              <div class="p-d-flex p-jc-end">
                <span class="p-input-icon-left ">
                  <i class="pi pi-search"/>
                  <InputText v-model="query.search" placeholder="Nombre, email o ig..."/>
                </span>
              </div>
            </div>

            <h3>Filtros</h3>

            <!-- Enabled Users Checkbox -->
            <div class="field-checkbox p-mt-2">
              <Checkbox id="enabledUsersFilter" v-model="query.enabled" :binary="true"/>
              <label for="enabledUsersFilter" class="p-ml-2">
                <div v-if="query.enabled">
                  Usuarios habilitados (aplicado)
                </div>

                <div v-else-if="query.enabled === false">
                  Usuarios Deshabilitados (aplicado)
                </div>

                <div v-else>
                  Usuarios habilitados (no aplicado)
                </div>
              </label>
            </div>

            <!-- Ig Validated Users Checkbox -->
            <div class="field-checkbox p-mt-2">
              <Checkbox id="validatedIgUsersFilter" v-model="query.ig_validated" :binary="true"/>
              <label for="validatedIgUsersFilter" class="p-ml-2">
                <div v-if="query.ig_validated">
                  Validados (instagram)
                </div>

                <div v-else-if="query.ig_validated === false">
                  No Validados (instagram)
                </div>

                <div v-else>
                  Validados (instagram) (no aplicado)
                </div>
              </label>
            </div>

            <!-- Deleted Users Checkbox -->
            <div class="field-checkbox p-mt-2">
              <Checkbox id="deletedUsersFilter" v-model="query.deleted" :binary="true"/>
              <label for="deletedUsersFilter" class="p-ml-2">
                <div v-if="query.deleted">
                  Eliminados (aplicado)
                </div>

                <div v-else-if="query.deleted === false">
                  No Eliminados (aplicado)
                </div>

                <div v-else>
                  Eliminados (no aplicado)
                </div>
              </label>
            </div>

            <!-- Rejected Users Checkbox -->
            <div class="field-checkbox p-mt-2">
              <Checkbox id="rejectedUsersFilter" v-model="query.rejected" :binary="true"/>
              <label for="rejectedUsersFilter" class="p-ml-2">
                <div v-if="query.rejected">
                  Rechazados (aplicado)
                </div>

                <div v-else-if="query.rejected === false">
                  No rechazados (aplicado)
                </div>

                <div v-else>
                  Rechazados (no aplicado)
                </div>
              </label>
            </div>

            <!-- Reset filters btn -->
            <div class="p-col-3">
              <Button label="Reset filters" class="p-button-sm" @click="resetQueryFilters"/>
            </div>
          </template>

          <Column field="name" header="Nombre">
            <template #body="{data}">
              <div v-tooltip.top="`ID: ${data.id} (${timestampToLocal(data.created_at)})`">
                <a href="#">
                  {{ data.name }}
                </a>
              </div>
            </template>
          </Column>

          <Column field="email" header="Email"></Column>

          <Column field="ig_username" header="Instagram">
            <template #body="{data}">
                <div v-if="data.ig_username">
                  <a target="_blank" v-if="data.ig_username" :href="`https://instagram.com/${data.ig_username}`">
                    @{{data.ig_username }}
                  </a>
                </div>

                <div v-else>
                  -
                </div>
            </template>
          </Column>

          <Column field="user_enabled" header="Habilitado?">
            <template #body="{data}">
              <div>
                {{ data.user.enabled ? "Si" : "No" }}
              </div>
            </template>
          </Column>

          <Column field="user_status" header="Estado">
            <template #body="{data}">

              <div v-if="data.user.ig_validated_at !== null">
                Validado (IG)
              </div>

              <div v-else-if="data.user.rejected">
                Rechazado
              </div>

              <div v-else>
                -
              </div>
            </template>
          </Column>
          <Column header="All Star">
            <template #body="{ data }">
              <p v-if="data.settings.skip_subscription" style="color: #1da750;" class="p-text-bold">Si</p>
            </template>
          </Column>
          <Column header="Covermanager ID">
            <template #body="{ data }">
              <p class="p-text-bold">{{ data.covermanager_id ? data.covermanager_id : '-'}}</p>
            </template>
          </Column>
          <Column header="">
            <template #body="{data}">
              <Button type="button" icon="pi pi-cog" class="p-button-rounded p-button-outlined"
                      @click="toggleActionMenu($event, data)" aria-haspopup="true" aria-controls="overlay_menu"/>
              <Menu id="overlay_menu" ref="action_menu" :model="actionMenuItems" :popup="true"/>
            </template>
          </Column>

          <template #empty>
            No se encontraron restaurantes
          </template>
          <template #loading>
            Cargando restaurantes
          </template>
        </DataTable>
      </div>
    </div>
  </div>

  <Toast/>

  <div v-if="displayAssignInvModal">
    <Dialog header="Confirmar invitaciones" v-model:visible="displayAssignInvModal" :style="{width: '50vw'}"
            :modal="true">
      <form action="">
        <div class="p-field">
          <div class="p-text-center">
            <label for="invitations_qty" class="p-d-block p-mb-2">Cantidad de invitaciones</label>
            <InputNumber ref="invitationsQtyInput" id="invitations_qty" v-model="invitationsQuantity" mode="decimal"
                         :useGrouping="false"
                         :min="1"/>
          </div>
        </div>
      </form>

      <template #footer>
        <Button label="Cancelar" icon="pi pi-times" @click="toggleAssignInvModal" class="p-button-text"/>
        <Button label="Confirmar" icon="pi pi-check" @click="assignInvitations" autofocus/>
      </template>
    </Dialog>
  </div>

  <div v-if="displayUploadCoverImage">
    <Dialog header="Subir imagen de cover" v-model:visible="displayUploadCoverImage" :style="{width: '50vw'}"
            :modal="true">
      <form action="">
        <div class="p-field">
          <div class="p-text-center">
            <label class="p-d-block p-mb-2">Subir cover</label>
            <FileUpload name="picture" :customUpload="true" @uploader="uploadCover" @upload="onUploadCover"
                        accept="image/*"/>
          </div>
        </div>
      </form>

      <template #footer>
        <Button label="Cancelar" icon="pi pi-times" @click="toggleUploadCoverModal" class="p-button-text"/>
      </template>
    </Dialog>
  </div>

  <div v-if="displaySetCoverManagerId">
    <Dialog header="Establecer CoverManager ID" v-model:visible="displaySetCoverManagerId" :style="{width: '50vw'}"
            :modal="true">
      <form action="">
        <div class="p-field">
          <div class="p-text-center">
            <label class="p-d-block p-mb-2">CoverManager ID</label>
            <InputText class="covermanager-id-input" type="text" ref="coverManagerIdInput" id="covermanager_id" v-model="coverManagerId"/>
          </div>
        </div>
      </form>

      <template #footer>
        <Button label="Cancelar" icon="pi pi-times" @click="toggleSetCoverManagerModal" class="p-button-text"/>
        <Button label="Confirmar" icon="pi pi-check" @click="setCoverManagerId" autofocus/>
      </template>
    </Dialog>
  </div>

  <div v-if="displaySetBookingsExternalLink">
    <Dialog header="Establecer link para reservas externas" v-model:visible="displaySetBookingsExternalLink" :style="{width: '50vw'}"
            :modal="true">
      <form action="">
        <div class="p-field">
          <div class="p-text-center">
            <label class="p-d-block p-mb-2">Link para reservas externas</label>
            <InputText class="bookings-external-link-input" type="text" ref="bookingsExternalLinkInput" id="bookings_external_link" v-model="bookingsExternalLink"/>
          </div>
        </div>
      </form>

      <template #footer>
        <Button label="Cancelar" icon="pi pi-times" @click="toggleSetBookingsExternalLink" class="p-button-text"/>
        <Button label="Confirmar" icon="pi pi-check" @click="setBookingsExternalLink" autofocus/>
      </template>
    </Dialog>
  </div>

  <div v-if="displayExtendsTrial">
    <Dialog header="Extender trial" v-model:visible="displayExtendsTrial" :style="{width: '50vw'}"
            :modal="true">
      <form action="">
        <div class="p-field">
          <div class="p-text-center">
            <label class="p-d-block p-mb-2">Dias</label>
            <input-number :min="1" ref="coverManagerIdInput" showButtons id="trials" v-model="extendsTrialValue"/>
          </div>
        </div>
      </form>

      <template #footer>
        <Button label="Cancelar" icon="pi pi-times" @click="displayExtendsTrial = false" class="p-button-text"/>
        <Button label="Confirmar" icon="pi pi-check" @click="extendsTrial()" autofocus/>
      </template>
    </Dialog>
  </div>

    <div v-if="displayMarkVIP">
        <Dialog header="Marcar como VIP" v-model:visible="displayMarkVIP" :style="{width: '50vw'}"
                :modal="true">
<!--            <form action="">-->
<!--                <div class="p-field">-->
<!--                    <div class="p-text-center">-->
<!--                        <label class="p-d-block p-mb-2">Dias</label>-->
<!--                        <input-number :min="1" ref="coverManagerIdInput" showButtons id="trials" v-model="extendsTrialValue"/>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </form>-->
          <h5 class="p-text-center">¿Desea <span class="p-text-bold">{{ selectedUser.settings.skip_subscription ? 'desmarcar' : 'marcar' }}</span> a este restaurante como All Star?</h5>

            <template #footer>
                <Button label="Cancelar" icon="pi pi-times" @click="displayMarkVIP = false" class="p-button-text"/>
                <Button label="Confirmar" icon="pi pi-check" @click="markVIP(selectedUser.settings.skip_subscription)" autofocus/>
            </template>
        </Dialog>
    </div>
</template>

<script>
import {dateToLocalTimeAM} from "../../utils";

export default {
  components: {},
  data() {
    return {
      restaurants: [],
      query: {
        'limit': 15,
        'paginate': true,
        'page': 1,
        'select': 'id,name,email,ig_username,profile_picture,verified,user_id,covermanager_id,created_at,deleted_at',
        'with': 'user',
        'sort_by': 'id,desc',
        'enabled': true,
        'deleted': false,
      },
      loading: false,
      searchDebounce: null,
      actionRestaurant: null,
      actionRestaurantId: null,
      actionMenuItems: [],
      displayAssignInvModal: false,
      displayUploadCoverImage: false,
      displaySetCoverManagerId: false,
      displaySetBookingsExternalLink: false,
      displayExtendsTrial: false,
      displayMarkVIP: false,
      invitationsQuantity: 1,
      coverManagerId: null,
      bookingsExternalLink: null,
      extendsTrialValue: 1,
      selectedUser: {}
    }
  },
  methods: {
    create() {
      this.$router.push({ name: 'restaurants.create' })
    },
    async getRestaurants() {
      this.loading = true;

      this.$repository.restaurants
          .list(this.query)
          .then(({ data: response }) => {
              this.restaurants = response
          })
          .catch(() => {
              this.$toast.add({severity: 'error', summary: 'Error', detail: 'Unable to get restaurants', life: 1000});
          })
          .finally(() => this.loading = false)
      // try {
      //   const response = await this.$repository.restaurants.list(this.query)
      //
      //   this.restaurants = response
      //   console.log(this.restaurant)
      // } catch (err) {
      //   this.$toast.add({severity: 'error', summary: 'Error', detail: 'Unable to get restaurants', life: 1000});
      // } finally {
      //   this.loading = false;
      // }
    },
    toggleAssignInvModal() {
      this.displayAssignInvModal = !this.displayAssignInvModal;
    },
    toggleUploadCoverModal() {
      this.displayUploadCoverImage = !this.displayUploadCoverImage;
    },
    toggleSetCoverManagerModal() {
      this.displaySetCoverManagerId = !this.displaySetCoverManagerId;
    },
    toggleSetBookingsExternalLink() {
      this.displaySetBookingsExternalLink = !this.displaySetBookingsExternalLink;
    },
    assignInvitations() {
      try {
        this.$repository.users.assignInvitations(this.actionRestaurant.user_id, {
          max_invitations: null,
          max_consumable_invitations: this.invitationsQuantity,
          invitation_expire_in_days: null
        })

        this.$toast.add({
          severity: 'success',
          summary: `${this.invitationsQuantity} invitaciones asignadas!`,
          life: 1000
        });

        this.actionRestaurant = null;
        this.invitationsQuantity = null;
        this.toggleAssignInvModal();

      } catch (err) {

        let detail = 'Unable to assign invitations'
        let summary = 'Error'

        if ('response' in err) {
          detail = err.response.data?.description
          summary = err.response.data?.message
        }

        this.$toast.add({severity: 'error', summary, detail, life: 1000});

      }
    },
    async enableUser() {
      if (this.actionRestaurant.user.enabled) {
        this.$toast.add({
          severity: 'info',
          summary: `El usuario ya esta habilitado`,
          life: 1000
        });

        return
      }

      try {
        const response = await this.$repository.users.enableUser(this.actionRestaurant.user_id)

        this.$toast.add({
          severity: 'success',
          summary: `El usuario ha sido habilitado`,
          life: 1000
        });

        this.updateRestUserById(this.actionRestaurant.id, response.data)

      } catch (err) {

        let detail = null
        let summary = 'No se pudo habilitar el usuario'

        if ('response' in err) {
          summary = err.response.data?.message
          detail = err.response.data?.description
        }

        this.$toast.add({severity: 'error', summary, detail, life: 1000});

      }

    },
    async disableUser() {
      if (this.actionRestaurant.user.enabled === false) {
        this.$toast.add({
          severity: 'info',
          summary: `El usuario ya esta deshabilitado`,
          life: 1000
        });

        return
      }

      try {
        const response = await this.$repository.users.enableUser(this.actionRestaurant.user_id, {
          enabled: false
        })

        this.$toast.add({
          severity: 'success',
          summary: `El usuario ha sido deshabilitado`,
          life: 1000
        });

        this.updateRestUserById(this.actionRestaurant.id, response.data)

      } catch (err) {

        let detail = null
        let summary = 'No se pudo deshabilitar el usuario'

        if ('response' in err) {
          summary = err.response.data?.message
          detail = err.response.data?.description
        }

        this.$toast.add({severity: 'error', summary, detail, life: 1000});

      }
    },
    async verifyRest(verified = true) {
      try {
        await this.$repository.restaurants.verify(this.actionRestaurant.id, {verified})

        this.$toast.add({
          severity: 'success',
          summary: `El restaurante fue verificado`,
          life: 1000
        });

      } catch (err) {

        let detail = null
        let summary = 'No se pudo verificar el restaurante'

        if ('response' in err) {
          summary = err.response.data?.message
          detail = err.response.data?.description
        }

        this.$toast.add({severity: 'error', summary, detail, life: 1000});

      }
    },
    async setCoverManagerId() {
      try {

        await this.$repository.restaurants.updateSettings(this.actionRestaurant.id, {
          covermanager_id: this.coverManagerId === '' ? null : this.coverManagerId
        })

        this.$toast.add({
          severity: 'success',
          summary: `El restaurante ha sido actualizado`,
          life: 5000
        });

        this.toggleSetCoverManagerModal();

        await this.getRestaurants();

      } catch (err) {

        let detail = null
        let summary = 'No se pudo actualizar el restaurante'

        if ('response' in err) {
          summary = err.response.data?.message
          detail = err.response.data?.description
        }

        this.$toast.add({severity: 'error', summary, detail, life: 5000});

      }
    },
    async setBookingsExternalLink() {
      try {

        await this.$repository.restaurants.updateSettings(this.actionRestaurant.id, {
          bookings_external_link: this.bookingsExternalLink === '' ? null : this.bookingsExternalLink
        })

        this.$toast.add({
          severity: 'success',
          summary: `El restaurante ha sido actualizado`,
          life: 5000
        });

        this.toggleSetBookingsExternalLink();

        await this.getRestaurants();

      } catch (err) {

        let detail = null
        let summary = 'No se pudo actualizar el restaurante'

        if ('response' in err) {
          summary = err.response.data?.message
          detail = err.response.data?.description
        }

        this.$toast.add({severity: 'error', summary, detail, life: 5000});

      }
    },
    toggleActionMenu(event, restaurant) {
      this.actionRestaurant = restaurant;

      this.actionMenuItems = [
        {
          label: 'Editar',
          command: () => {
            this.$router.push({ name: 'restaurants.update', params: { restaurantId: restaurant.id } })
          }
        },
        {
          label: 'Asignar invitaciones',
          icon: 'pi pi-plus',
          command: () => {
            this.toggleAssignInvModal();
          }
        },
        {
          label: 'Imagen de cover',
          icon: 'pi pi-plus',
          command: () => {
            this.toggleUploadCoverModal();
          }
        },
        {
          label: 'CoverManager ID',
          icon: 'pi pi-plus',
          command: () => {
            this.coverManagerId = this.actionRestaurant.covermanager_id;

            this.toggleSetCoverManagerModal();
          }
        },
        {
          label: 'Link para reservas externas',
          icon: 'pi pi-plus',
          command: () => {
            this.bookingsExternalLink = this.actionRestaurant.settings["bookings_external_link"];

            this.toggleSetBookingsExternalLink();
          }
        },
        {
          label: 'Extender Trial',
          icon: 'pi pi-plus',
          command: () => {
            this.selectedUser = this.actionRestaurant
            this.displayExtendsTrial = true;
          }
        },
        {
          label: `${ this.actionRestaurant.settings.skip_subscription ? 'Desmarcar' : 'Marcar' } VIP`,
          command: () => {
            this.selectedUser = this.actionRestaurant
            this.displayMarkVIP = true;
          }
        },
        // {
        //   label: 'Actualizar Instagram data',
        //   command: () => {
        //     this.selectedUser = this.actionRestaurant
        //     this.updateIgData()
        //   }
        // }
      ]

      if (this.actionRestaurant.covermanager_id) {
        this.actionMenuItems.push(
            {
              label: 'Actualizar información de instagram',
              command: () => {
                this.selectedUser = this.actionRestaurant
                this.updateIgData()
              }
            }
        );
      }

      if (restaurant.user.enabled) {
        this.actionMenuItems.push(
            {
              label: 'Deshabilitar usuario',
              command: () => {
                if (confirm('Confirmar deshabilitar, el usuario no podrá iniciar sesión')) {
                  this.disableUser();
                }
              }
            }
        );
      } else {
        this.actionMenuItems.push(
            {
              label: 'Habilitar usuario',
              command: () => {
                if (confirm('Confirmar habilitar, el usuario podrá iniciar sesión')) {
                  this.enableUser();
                }
              }
            },
        );
      }

      if (restaurant.user.ig_validated_at === null) {
        this.actionMenuItems.push(
            {
              label: 'Validar instagram',
              command: () => {
                const validationCode = prompt('Confirmar validación, la cuenta de este restaurante sera validada', 'Inserte aquí el código!');

                if (validationCode) {
                  this.validateInstagram(validationCode);
                }
              }
            },
        );
      }

      if (restaurant.deleted_at === null) {
        this.actionMenuItems.push(
            {
              label: 'Eliminar',
              command: () => {
                if (confirm('Seguro que desea eliminar este restaurante?')) {
                  this.deleteRestaurant();
                }
              }
            },
        );
      } else {
        // already deleted
        this.actionMenuItems.push(
            {
              label: 'Restaurar',
              command: () => {
                if (confirm('Seguro que desea restaurar este restaurante?')) {
                  this.restoreRestaurant();
                }
              }
            },
        );
      }

      if (restaurant.user.ig_validated_at === null) {
        if (restaurant.user.rejected === null) { // no rejected
          this.actionMenuItems.push(
              {
                label: 'Rechazar',
                command: () => {
                  const rejectedReason = prompt('Razón de rechazo (opcional)', null);

                  if (confirm('Seguro que desea rechazar este usuario?')) {
                    this.reject(rejectedReason);
                  }
                }
              },
          );
        }
      }

      this.$refs.action_menu.toggle(event);
    },
    updateRestUserById(restId, user) {
      return this.restaurants.forEach((restaurant, index) => {
        if (restaurant.id === restId) {
          const updated = {...restaurant}
          updated.user = user

          this.restaurants[index] = updated
        }
      });
    },
    async uploadCover(event) {

      const formData = new FormData();
      formData.append('picture', event.files[0]);

      try {
        await this.$repository.restaurants.uploadCoverPicture(this.actionRestaurant.id, formData)

        this.$toast.add({
          severity: 'success',
          summary: 'File uploaded',
          life: 1000,
          autoZIndex: false,
          baseZIndex: 9999,
        });

      } catch (err) {
        let detail = null
        let summary = 'No se pudo subir la imagen de cover'

        if ('response' in err) {
          summary = err.response.data?.message
          detail = err.response.data?.description
        }

        this.$toast.add({severity: 'error', summary, detail, life: 1000});
      }
    },
    timestampToLocal(date) {
      return dateToLocalTimeAM(date);
    },
    resetQueryFilters() {
      this.query.enabled = true;
      this.query.deleted = false;
      this.query.rejected = null;
      this.query.ig_validated = null;
    },
    async deleteRestaurant() {
      if (this.actionRestaurant.deleted_at !== null) {
        this.$toast.add({
          severity: 'info',
          summary: `El restaurante ya ha sido eliminado`,
          life: 3000
        });

        return;
      }

      try {
        await this.$repository.restaurants.delete(this.actionRestaurant.id);

        this.$toast.add({
          severity: 'success',
          summary: `Restaurante eliminado`,
          life: 2000
        });

        await this.getRestaurants();

      } catch (err) {

        let code = null
        let detail = null
        let summary = 'No se pudo eliminar el restaurante'

        if ('response' in err) {
          code = err.response.data?.error_code
          summary = `(${code}) ${err.response.data?.message}`
          detail = err.response.data?.description
        }

        this.$toast.add({severity: 'error', summary, detail, life: 3000});
      }
    },
    async restoreRestaurant() {
      if (this.actionRestaurant.deleted_at == null) {
        this.$toast.add({
          severity: 'info',
          summary: `El restaurante ya esta restaurado`,
          life: 3000
        });

        return;
      }

      try {
        await this.$repository.restaurants.restore(this.actionRestaurant.id);

        this.$toast.add({
          severity: 'success',
          summary: `Restaurante restaurado`,
          life: 2000
        });

        await this.getRestaurants();

      } catch (err) {

        let code = null
        let detail = null
        let summary = 'No se pudo restaurar el restaurante'

        if ('response' in err) {
          code = err.response.data?.error_code
          summary = `(${code}) ${err.response.data?.message}`
          detail = err.response.data?.description
        }

        this.$toast.add({severity: 'error', summary, detail, life: 3000});
      }
    },
    async validateInstagram(validationCode) {
      if (this.actionRestaurant.user.ig_validated_at !== null) {
        this.$toast.add({
          severity: 'info',
          summary: `El restaurante ya ha sido validado`,
          life: 3000
        });

        return
      }

      try {
        const response = await this.$repository.users.validateInstagram(this.actionRestaurant.user_id, {
          validation_code: validationCode
        })

        this.$toast.add({
          severity: 'success',
          summary: `Instagram validado!`,
          life: 2000
        });

        this.updateRestUserById(this.actionRestaurant.id, response.data)

        this.getRestaurants();

      } catch (err) {

        let code = null
        let detail = null
        let summary = 'No se pudo validar el restaurante'

        if ('response' in err) {
          code = err.response.data?.error_code
          summary = `(${code}) ${err.response.data?.message}`
          detail = err.response.data?.description
        }

        this.$toast.add({severity: 'error', summary, detail, life: 3000});
      }
    },
    async reject(reason) {
      if (this.actionRestaurant.user.rejected) {
        this.$toast.add({
          severity: 'info',
          summary: `El usuario ya esta rechazado`,
          life: 3000
        });

        return;
      }

      try {
        await this.$repository.users.reject(this.actionRestaurant.user_id, reason);

        this.$toast.add({
          severity: 'success',
          summary: `Usuario rechazado`,
          life: 2000
        });

        await this.getRestaurants();

      } catch (err) {

        let code = null
        let detail = null
        let summary = 'No se pudo rechazar el usuario'

        if ('response' in err) {
          code = err.response.data?.error_code
          summary = `(${code}) ${err.response.data?.message}`
          detail = err.response.data?.description
        }

        this.$toast.add({severity: 'error', summary, detail, life: 3000});
      }
    },
    async updateIgData () {
      this.$repository.users
        .updateIg(this.selectedUser.user_id)
        .then(() => {
          this.$toast.add({
            severity: 'info',
            summary: `Actualización exitosa`,
            life: 3000
          })
        })
      .catch(() => {
        this.$toast.add({
          severity: 'error',
          summary: `Ha ocurrido un error al actualizar la información.`,
          life: 3000
        })
      })
    },
    extendsTrial () {
      this.$repository.subscription
        .extendsTrial(this.selectedUser.user_id, { trial_days: this.extendsTrialValue })
        .then(() => {
          this.$toast.add({
            severity: 'success',
            summary: `Se ha extendido el tiempo de pruebas`,
            life: 5000
          });
          this.displayExtendsTrial = false
        })
        .catch(() => {
          this.$toast.add({severity: 'error', summary: 'Error', detail: 'No se pudo extender el tiempo de pruebas', life: 5000});
          this.displayExtendsTrial = false
        })
    },
    markVIP (vip) {
        if (vip) {
            this.$repository.restaurants
                .removeSkipSubscription(this.selectedUser.id)
                .then(() => {
                    this.$toast.add({ severity: 'success', summary: `Se ha marcado como All Star correctamente.`, life: 5000 })
                    this.getRestaurants()
                })
                .catch(() => {
                    this.$toast.add({ severity: 'error', summary: 'Error', detail: 'No se pudo marcar/desmarcar el restaurante.', life: 5000 })
                })
            this.displayMarkVIP = false
        } else {
            this.$repository.restaurants
                .skipSubscription(this.selectedUser.id)
                .then(() => {
                    this.$toast.add({ severity: 'success', summary: `Se ha marcado como All Star correctamente.`, life: 5000 })
                    this.getRestaurants()
                })
                .catch((err) => {
                    console.log(err.response.data)
                    this.$toast.add({ severity: 'error', summary: 'Error', detail: 'No se pudo marcar/desmarcar el restaurante.', life: 5000 })
                })
            this.displayMarkVIP = false
        }

    }
  },
  watch: {
    'query.search': function (newVal) {

      if (newVal === null) {
        return;
      } else if (newVal === '') {
        this.query.search = null;

        return;
      }

      if (this.searchDebounce) {
        clearTimeout(this.searchDebounce)
      }

      this.searchDebounce = setTimeout(() => {
        this.getRestaurants();
      }, 600)
    },
    'query.enabled': function () {
      this.getRestaurants();
    },
    'query.deleted': function () {
      this.getRestaurants();
    },
    'query.rejected': function () {
      this.getRestaurants();
    },
    'query.ig_validated': function () {
      this.getRestaurants();
    },
    'query.page': function () {
      this.getRestaurants()
    }
  },
  mounted() {
    this.getRestaurants();
  }
}
</script>

<style scoped>
.field-checkbox, .field-radiobutton {
  margin-bottom: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.covermanager-id-input {
  width: 300px;
}

.bookings-external-link-input {
  margin-left: 10px;
  margin-right: 10px;
  width: 100%;
}
</style>
